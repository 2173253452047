import React from 'react'
import UnderDevNotifier from '../../components/UnderDevNotifier'

const AchievementsPage = () => {
    return (
        <div>
            AchievementsPage
            <UnderDevNotifier animationHeight="300px" />
        </div>
    )
}

export default AchievementsPage