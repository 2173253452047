import React from 'react'

const Ypkold = () => {
    return (
        <div>
            <h2 className='text-lg'>YunusPK-old is a project I have built in 2020 using only html, css & vanilla javascript. That was my first personality website. This website is
                inspired by this project as well.
            </h2>
        </div>
    )
}

export default Ypkold;