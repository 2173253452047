import React from 'react'
import UnderDevNotifier from '../../components/UnderDevNotifier';

const ContactsPage = () => {
    return (
        <div>
            ContactPage
            <UnderDevNotifier animationHeight="300px" />
        </div>
    )
}

export default ContactsPage;