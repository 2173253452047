import React from 'react'
import UnderDevNotifier from '../../components/UnderDevNotifier'

const ProjectsPage = () => {
    return (
        <div>
            ProjectsPage
            <UnderDevNotifier animationHeight="300px" />
        </div>
    )
}

export default ProjectsPage