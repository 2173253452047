import React from 'react';
import UnderDevNotifier from '../../components/UnderDevNotifier';

const AboutPage = () => {
    return (
        <div>
            AboutPage
            <UnderDevNotifier animationHeight="300px" />
        </div>
    )
}

export default AboutPage